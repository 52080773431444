<template>
  <div
    class="tab-pane fade show active championship condition"
    id="pills-1"
    role="tabpanel"
    aria-labelledby="pills-1-tab"
  >
    <div class="tourn-card mt-3 mb-5">
      <div class="card flex-md-row align-items-md-center">
        <div class="col-md-4 card-img-wrapper order-md-2">
          <img
            :src="
              tourbinary && tourbinary[0] && tourbinary[0].filepath | get_img
            "
          />
        </div>
        <div class="card-body order-md-1 ps-md-5">
          <span class="badge rounded-pill">{{
            tournament_type(tournamentinfo.type)
          }}</span>
          <h4 class="card-title lh-sm text-white">
            {{ tournamentinfo.title }}
          </h4>
          <p class="info mb-5">{{ tournamentinfo.remark }}</p>
          <small class="text-date">
            <!-- 서비스 시간 -->
            {{
              tournamentinfo.timezone_startdate &&
              tournamentinfo.timezone_startdate.timezone_common
                | dateformat("YYYY.MM.DD")
            }}
            ~
            {{
              tournamentinfo.timezone_startdate &&
              tournamentinfo.timezone_enddate.timezone_common
                | dateformat("YYYY.MM.DD")
            }}
          </small>
        </div>
      </div>
    </div>

    <!-- <p class="info mb-5">{{ tournamentinfo.remark }}</p> -->

    <div class="border-top-dgray mt-4">
      <div class="row g-0 border-bottom">
        <div class="col-4 col-lg-2 bg-gray row g-0 g-0 align-items-center">
          <label class="d-table-cell">{{ $t("title.common.course") }}</label>
        </div>
        <div class="col-8 col-lg-10">
          <p class="d-table-cell">
            <span v-for="(course, index) in tournamentcourse" :key="index"
              >{{ index === 0 ? "" : ", " }}{{ course.coursename }}</span
            >
          </p>
        </div>
      </div>
      <div class="row g-0 border-bottom">
        <div class="row g-0 col-4 col-lg-2 bg-gray align-item-center">
          <label class="d-table-cell align-self-center">{{
            $t("title.common.subcourse")
          }}</label>
        </div>
        <div v-if="subcourselistcnt" class="col-8 col-lg-10">
          <div
            v-for="(anc, index1) in subcourselist"
            :key="'k_' + index1"
            class="subcourse"
          >
            <h6 class="pt-1">{{ anc.subcoursename }}</h6>
            <small class="d-block text-dgray"
              >{{ $t("title.common.subcourse") }} {{ anc.subcourseseq }}</small
            >
          </div>
        </div>
      </div>
    </div>

    <div class="border-top-dgray mt-5">
      <!-- <div class="row g-0">
        <div class="col-lg-2 row g-0 bg-gray border-bottom align-items-center">
          <label class="d-table-cell">매장</label>
        </div>
        <div class="col-lg-10 border-bottom">
          <p class="d-table-cell">
            <span v-for="(shop, index2) in tournamentshop" :key="index2"
              >{{ index2 === 0 ? "" : " / " }}{{ shop.shopname }}</span
            >
          </p>
        </div>
      </div> -->
      <div class="row g-0">
        <div class="col-lg-2 row g-0 bg-gray border-bottom align-items-center">
          <label class="d-table-cell">{{ $t("title.common.prizes") }}</label>
        </div>
        <div class="col-lg-10 border-bottom">
          <div class="row g-0 border-bottom">
            <div class="col-lg-12">
              <p class="d-table-cell">
                {{ tournamentprizeetc.etcprize || "Unregistered" }}
              </p>
            </div>
          </div>
          <div class="row g-0 border-bottom-0">
            <div class="col-lg-12">
              <p class="d-table-cell">
                <span
                  class="d-block"
                  v-for="(rank, index3) in tournamentprizerank"
                  :key="index3"
                  ><strong class="fw-500 text-black"
                    >Top {{ rank.rankno }}
                  </strong>
                  :
                  <span class="text-point">{{ rank.rankprize | comma }}</span>
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="row g-0">
        <div class="col-lg-2 bg-gray row g-0 align-items-center border-bottom">
          <label class="d-table-cell">{{
            $t("title.common.tournament_conditions")
          }}</label>
        </div>
        <div class="col-lg-10">
          <div class="row g-0 border-bottom-0">
            <div
              class="col-lg-6 d-table-cell d-flex flex-column align-items-stretch"
            >
              <div class="row g-0 border-bottom">
                <div class="col-4 bg-lightgray row g-0 align-items-center">
                  <label class="d-table-cell">{{
                    $t("title.common.game_mode")
                  }}</label>
                </div>
                <div class="col-8">
                  <p class="d-table-cell">
                    {{ tournamentinfo.mode }}
                  </p>
                </div>
              </div>
              <div class="row g-0 border-bottom">
                <div class="col-4 bg-lightgray row g-0 align-items-center">
                  <label class="d-table-cell">{{
                    $t("title.common.level")
                  }}</label>
                </div>
                <div class="col-8">
                  <p class="d-table-cell">
                    {{ tournament_level(tournamentinfo.level) }}
                  </p>
                </div>
              </div>
              <div class="row g-0 border-bottom">
                <div class="col-4 bg-lightgray row g-0 align-items-center">
                  <label class="d-table-cell">{{
                    $t("title.common.entry_limit")
                  }}</label>
                </div>
                <div class="col-8">
                  <p class="d-table-cell text-red">
                    {{ tournamentinfo.entrylimit | transToValue("∞") }}
                  </p>
                </div>
              </div>
              <div
                class="row g-0 border-bottom d-flex flex-row align-items-stretch h-100"
              >
                <div class="col-4 bg-lightgray row g-0 align-items-center">
                  <label class="d-table-cell">{{
                    $t("title.common.mulligan")
                  }}</label>
                </div>
                <div class="col-8 row g-0 align-items-center">
                  <p class="d-table-cell">
                    {{ tournamentinfo.mulligan }}
                  </p>
                </div>
              </div>
            </div>
            <div
              class="col-lg-6 d-table-cell d-flex flex-column align-items-stretch"
            >
              <div class="row g-0 border-bottom">
                <div class="col-4 bg-lightgray row g-0 align-items-center">
                  <label class="d-table-cell">{{
                    $t("title.common.play_format")
                  }}</label>
                </div>
                <div class="col-8">
                  <p class="d-table-cell">{{ tournamentinfo.hole }}H</p>
                </div>
              </div>
              <div class="row g-0 border-bottom">
                <div class="col-4 bg-lightgray row g-0 align-items-center">
                  <label class="d-table-cell">{{
                    $t("title.common.wind")
                  }}</label>
                </div>
                <div class="col-8">
                  <p class="d-table-cell">{{ wind }}</p>
                </div>
              </div>
              <div class="row g-0 border-bottom">
                <div class="col-4 bg-lightgray row g-0 align-items-center">
                  <label class="d-table-cell">-</label>
                </div>
                <div class="col-8">
                  <p class="d-table-cell"></p>
                </div>
              </div>
              <div class="row g-0 border-bottom">
                <div class="col-4 bg-lightgray row g-0 align-items-center">
                  <label class="d-table-cell">{{
                    $t("title.common.teebox")
                  }}</label>
                </div>
                <div class="col-8">
                  <p class="d-table-cell">
                    {{ $t("title.common.male") }}: {{ teemale }}<br />
                    {{ $t("title.common.female") }}: {{ teefemale }}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="row g-0 border-bottom-0">
            <div class="col-lg-3 bg-gray row align-items-center">
              <label class="d-table-cell">Handicap(No. of Play)</label>
            </div>
            <div class="col-lg-9">
              <p class="d-table-cell">{{ tournamentinfo.playhandicap }}</p>
            </div>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ax from "@/api/tournament";
import "@/filter/common";
import { myMixin } from "@/mixin/myMixin";

export default {
  name: "Condition",
  data() {
    return {
      tournamentno: this.$route.params.id,
      tournamentinfo: [
        {
          title: "",
          remark: "",
          startdate: "",
          enddate: "",
          mode: "",
          hole: "",
          level: "",
          wind: "",
          mulligan: "",
          entrylimit: "",
          teeboxman: "",
          teeboxwoman: "",
          playhandicap: "",
        },
      ],
      tournamentcourse: [],
      tournamentshop: [],
      tournamentprizeetc: [
        {
          etcprize: "",
        },
      ],
      tournamentprizerank: [],
      tournamentinfo: [{}],
      wind: "",
      teemale: "",
      teefemale: "",
      subcourselist: [],
      tourbinary: [],
      subcourselistcnt: 0,
    };
  },
  methods: {
    get_tournamentcondition() {
      ax.get_tournamentcondition(this.tournamentno, (flag, data) => {
        // console.log(flag, data);
        if (flag) {
          this.tournamentinfo = data.tournamentinfo;
          this.tournamentcourse = data.tournamentcourse;
          this.tournamentshop = data.tournamentshop;
          this.tournamentprizeetc = data.tournamentprizeetc;
          this.tournamentprizerank = data.tournamentprizerank;

          this.subcourselist = data.subcourselist;
          this.subcourselistcnt = data.subcourselistcnt;
          this.tourbinary = data.tourbinary;
          this.change_wind(this.tournamentinfo.wind);
          this.change_male(this.tournamentinfo.teeboxman);
          this.change_female(this.tournamentinfo.teeboxwoman);
        } else {
          alert(data);
        }
      });
    },
    // change_wind(wind) {
    //   if (wind === "0") {
    //     this.wind = this.$i18n.t("text.common.calm");
    //   } else if (wind === "1") {
    //     this.wind = this.$i18n.t("text.common.light");
    //   } else if (wind === "2") {
    //     this.wind = this.$i18n.t("text.common.moderate");
    //   } else if (wind === "3") {
    //     this.wind = this.$i18n.t("text.common.strong");
    //   } else if (wind === "4") {
    //     this.wind = this.$i18n.t("text.common.random");
    //   } else {
    //     this.wind = wind;
    //   }
    // },
    // change_male(teeboxman) {
    //   if (teeboxman === "1") {
    //     this.teemale =
    //       this.$i18n.t("text.common.pro") +
    //       "(" +
    //       this.$i18n.t("text.common.blue") +
    //       ")";
    //   } else if (teeboxman === "2") {
    //     this.teemale =
    //       this.$i18n.t("text.common.regular") +
    //       "(" +
    //       this.$i18n.t("text.common.white") +
    //       ")";
    //   } else if (teeboxman === "3") {
    //     this.teemale =
    //       this.$i18n.t("text.common.lady") +
    //       "(" +
    //       this.$i18n.t("text.common.red") +
    //       ")";
    //   } else {
    //     this.teemale = teeboxman;
    //   }
    // },
    // change_female(teeboxwoman) {
    //   if (teeboxman === "1") {
    //     this.teemale =
    //       this.$i18n.t("text.common.pro") +
    //       "(" +
    //       this.$i18n.t("text.common.blue") +
    //       ")";
    //   } else if (teeboxman === "2") {
    //     this.teemale =
    //       this.$i18n.t("text.common.regular") +
    //       "(" +
    //       this.$i18n.t("text.common.white") +
    //       ")";
    //   } else if (teeboxman === "3") {
    //     this.teemale =
    //       this.$i18n.t("text.common.lady") +
    //       "(" +
    //       this.$i18n.t("text.common.red") +
    //       ")";
    //   } else {
    //     this.teefemale = teeboxwoman;
    //   }
    // },
  },
  created() {
    this.get_tournamentcondition();
  },
  mounted() {
    // window.scrollTo(0, 0);
  },
  mixins: [myMixin],
};
</script>
