<template>
  <div>
    <location />
    <div class="page wrap-1200">
      <h3 class="subtitle">{{ $t("title.membership.round_summary") }}</h3>
      <div class="row row-cols-1 row-cols-md-2 g-0 border-top-dgray">
        <div class="col border-bottom">
          <div class="row g-0">
            <div class="col bg-gray row g-0 align-items-center">
              <label class="d-table-cell">{{
                $t("title.common.avg_score")
              }}</label>
            </div>
            <div class="col">
              <p class="d-table-cell">
                {{
                  summaryinfo.avgscore
                    ? null || summaryinfo.avgscore
                    : "-" | comma
                }}
              </p>
            </div>
          </div>
        </div>
        <div class="col border-bottom">
          <div class="row g-0">
            <div class="col bg-gray row g-0 align-items-center">
              <label class="d-table-cell">{{
                $t("title.common.max_distance")
              }}</label>
            </div>
            <div class="col">
              <p class="d-table-cell">
                {{
                  summaryinfo.maxdriver
                    ? null || summaryinfo.maxdriver + "m"
                    : "-" | comma
                }}
              </p>
            </div>
          </div>
        </div>

        <div class="col border-bottom">
          <div class="row g-0">
            <div class="col bg-gray row g-0 align-items-center">
              <label class="d-table-cell">{{
                $t("title.common.avg_putts_distance")
              }}</label>
            </div>
            <div class="col">
              <p class="d-table-cell">
                {{ summaryinfo.avggreenhit | toFixed }}m
              </p>
            </div>
          </div>
        </div>
        <div class="col border-bottom">
          <div class="row g-0">
            <div class="col bg-gray row g-0 align-items-center">
              <label class="d-table-cell">{{
                $t("title.membership.swing_video_count")
              }}</label>
            </div>
            <div class="col">
              <p class="d-table-cell">
                {{
                  summaryinfo.swingcnt
                    ? null || summaryinfo.swingcnt
                    : "-" | comma
                }}
              </p>
            </div>
          </div>
        </div>

        <div class="col border-bottom">
          <div class="row g-0">
            <div class="col bg-gray row g-0 align-items-center">
              <label class="d-table-cell">{{
                $t("title.common.total_rounds")
              }}</label>
            </div>
            <div class="col">
              <p class="d-table-cell">
                {{
                  summaryinfo.totalgamecnt
                    ? null || summaryinfo.totalgamecnt
                    : "-" | comma
                }}
              </p>
            </div>
          </div>
        </div>
        <div class="col border-bottom">
          <div class="row g-0">
            <div class="col bg-gray row g-0 align-items-center">
              <label class="d-table-cell">{{
                $t("title.common.handicap")
              }}</label>
            </div>
            <div class="col">
              <p class="d-table-cell">
                {{ summaryinfo.handicap > 0 ? "+" : ""
                }}{{ summaryinfo.handicap }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <TermDefinition getname="score" />

      <h3 class="subtitle">
        <router-link :to="{ name: 'MyRounds' }">
          {{ $t("title.membership.recent_round")
          }}<span class="material-icons-outlined">navigate_next</span>
        </router-link>
      </h3>
      <div class="row row-cols-1 row-cols-md-2 g-0 border-top-dgray">
        <div class="col border-bottom">
          <div class="row g-0">
            <div class="col bg-gray row g-0 align-items-center">
              <label class="d-table-cell">{{ $t("title.common.date") }}</label>
            </div>
            <div class="col">
              <p class="d-table-cell">
                <!-- 사용자 시간 -->
                <span class="text-date">
                  {{
                    recentplayinfo.timezone_date &&
                    recentplayinfo.timezone_date.timezone_common | dateformat
                  }}
                </span>
              </p>
            </div>
          </div>
        </div>
        <div class="col border-bottom">
          <div class="row g-0">
            <div class="col bg-gray row g-0 align-items-center">
              <label class="d-table-cell">{{
                $t("title.common.course")
              }}</label>
            </div>
            <div class="col">
              <p class="d-table-cell">
                {{
                  recentplayinfo.coursename
                    ? null || recentplayinfo.coursename
                    : "-"
                }}
              </p>
            </div>
          </div>
        </div>

        <div class="col border-bottom">
          <div class="row g-0">
            <div class="col bg-gray row g-0 align-items-center">
              <label class="d-table-cell">{{ $t("title.common.score") }}</label>
            </div>
            <div class="col">
              <p class="d-table-cell">
                {{ Math.sign(recentplayinfo.score) > 0 ? "+" : ""
                }}{{ recentplayinfo.score }}
                <!-- {{ recentplayinfo.score ? null || recentplayinfo.score  : "-" | comma }}               -->
              </p>
            </div>
          </div>
        </div>
        <div class="col border-bottom">
          <div class="row g-0">
            <div class="col bg-gray row g-0 align-items-center">
              <label class="d-table-cell">{{ $t("title.common.shot") }}</label>
            </div>
            <div class="col">
              <p class="d-table-cell">
                {{
                  recentplayinfo.shot
                    ? null || recentplayinfo.shot
                    : "-" | comma
                }}
              </p>
            </div>
          </div>
        </div>

        <div class="col border-bottom">
          <div class="row g-0">
            <div class="col bg-gray row g-0 align-items-center">
              <label class="d-table-cell">{{
                $t("title.common.max_distance")
              }}</label>
            </div>
            <div class="col">
              <p class="d-table-cell">
                {{
                  recentplayinfo.maxdriver
                    ? null || recentplayinfo.maxdriver + "m"
                    : "-" | comma
                }}
              </p>
            </div>
          </div>
        </div>
        <div class="col border-bottom">
          <div class="row g-0">
            <div class="col bg-gray row g-0 align-items-center">
              <label class="d-table-cell">{{
                $t("title.common.avg_distance")
              }}</label>
            </div>
            <div class="col">
              <p class="d-table-cell">
                {{
                  recentplayinfo.avgdriver
                    ? null || recentplayinfo.avgdriver + "m"
                    : "-" | comma
                }}
              </p>
            </div>
          </div>
        </div>

        <div class="col border-bottom">
          <div class="row g-0">
            <div class="col bg-gray row g-0 align-items-center">
              <label class="d-table-cell">{{
                $t("title.common.fareway_hit")
              }}</label>
            </div>
            <div class="col">
              <p class="d-table-cell">
                {{
                  recentplayinfo.farewayrate
                    ? null || recentplayinfo.farewayrate + "%"
                    : "-" | comma
                }}
              </p>
            </div>
          </div>
        </div>
        <div class="col border-bottom">
          <div class="row g-0">
            <div class="col bg-gray row g-0 align-items-center">
              <label class="d-table-cell">{{
                $t("title.common.par_save_rate")
              }}</label>
            </div>
            <div class="col">
              <p class="d-table-cell">
                {{
                  recentplayinfo.parsaverate
                    ? null || recentplayinfo.parsaverate + "%"
                    : "-" | comma
                }}
              </p>
            </div>
          </div>
        </div>

        <div class="col border-bottom">
          <div class="row g-0">
            <div class="col bg-gray row g-0 align-items-center">
              <label class="d-table-cell">{{ $t("title.common.gir") }}</label>
            </div>
            <div class="col">
              <p class="d-table-cell">
                {{
                  recentplayinfo.greenrate
                    ? null || recentplayinfo.greenrate + "%"
                    : "-" | comma
                }}
              </p>
            </div>
          </div>
        </div>
        <div class="col border-bottom">
          <div class="row g-0">
            <div class="col bg-gray row g-0 align-items-center">
              <label class="d-table-cell">{{
                $t("title.common.avg_putts_distance")
              }}</label>
            </div>
            <div class="col">
              <p class="d-table-cell">
                {{ summaryinfo.avggreenhit | toFixed }}m
              </p>
            </div>
          </div>
        </div>
      </div>
      <TermDefinition getname="score" />

      <h3 class="subtitle">
        <router-link :to="{ name: 'MySwing' }">
          {{ $t("title.membership.recent_swing_video")
          }}<span class="material-icons-outlined">navigate_next</span>
        </router-link>
      </h3>
      <table class="table board" id="dataTable">
        <thead>
          <tr>
            <th class="col-auto d-lg-table-cell">
              {{ $t("title.common.swing_video") }}
            </th>
            <th class="col-auto d-lg-table-cell">
              {{ $t("title.common.location") }}
            </th>
            <th class="col-auto d-none d-lg-table-cell">
              {{ $t("title.common.date") }}
            </th>
            <th class="th-arrow">&nbsp;</th>
          </tr>
        </thead>
        <tbody v-if="swingdata_yn">
          <tr>
            <td class="col-auto d-lg-table-cell w-1">
              <router-link
                :to="{
                  name: 'MySwingView',
                  params: { id: swinginfo.swingno },
                }"
                class="position-relative video-box"
              >
                <video
                  :src="swinginfo.path + '/' + swinginfo.swingmovie"
                ></video>
                <div class="mask"></div>
                <span class="icon-play"></span>
              </router-link>
            </td>
            <td class="col-auto d-lg-table-cell shop-name">
              {{ swinginfo.shopname }}
            </td>
            <td class="col-auto d-none d-lg-table-cell text-date w-1">
              {{
                swinginfo.timezone_date &&
                swinginfo.timezone_date.timezone_common | dateformat
              }}
            </td>
            <td class="td-arrow" @click="toggle_tr(1)">
              <img
                src="/img/arrow_none_collapsed.png"
                :class="{ 'btn-toggle-arrow': view_hide === 1 }"
              />
            </td>
          </tr>
          <tr
            class="d-lg-none"
            :class="{ show: view_hide === 1 }"
            v-if="view_hide === 1"
          >
            <td colspan="3">
              <div class="toggle-content">
                <div class="row">
                  <div class="col-4">
                    {{ $t("title.common.date") }}
                  </div>
                  <div class="col-8 text-date">
                    {{
                      swinginfo.timezone_date &&
                      swinginfo.timezone_date.timezone_common | dateformat
                    }}
                  </div>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <td colspan="3" class="text-center">
              {{ $t("text.common.no_data") }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import location from "@/components/Location";
import ax from "@/api/membership";
import "@/filter/common";
import TermDefinition from "@/components/TermDefinition";

export default {
  name: "MySummary",
  components: {
    location,
    TermDefinition,
  },
  data() {
    return {
      summaryinfo: [],
      recentplayinfo: [],
      swinginfo: [],
      swingdata_yn: true,
      view_hide: null,
    };
  },
  methods: {
    get_membersummary() {
      ax.get_membersummary((flag, data) => {
        console.log(flag, data);
        if (flag) {
          this.summaryinfo = data.summaryinfo;
          this.recentplayinfo = data.recentplayinfo;
          this.swinginfo = data.swinginfo;
          if (data.swinginfo.length <= 0) {
            this.swingdata_yn = false;
            return false;
          } else {
            this.swingdata_yn = true;
          }
        } else {
          alert(data.message);
          if (code === 401) {
            this.$store.dispatch("logout");
            this.$router.push("/");
          }
        }
      });
    },
    toggle_tr(index) {
      if (this.view_hide === index) this.view_hide = null;
      else this.view_hide = index;
    },
  },
  created() {
    this.get_membersummary();
  },
};
</script>
