var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('location'),_c('div',{staticClass:"page"},[_c('swiper',{staticClass:"swiper mySwiper",attrs:{"options":{
        slidesPerView: 'auto',
        freeMode: true,
        initialSlide: _vm.tab - 1,
      }}},[_c('swiper-slide',{class:{ active: _vm.tab === 1 }},[_c('div',{on:{"click":function($event){return _vm.tab_change(1)}}},[_vm._v(" "+_vm._s(_vm.$t("button.tournament.tournament_condition"))+" ")])]),_c('swiper-slide',{class:{ active: _vm.tab === 2 }},[_c('div',{on:{"click":function($event){return _vm.tab_change(2)}}},[_vm._v(" "+_vm._s(_vm.$t("button.tournament.tournament_rank"))+" ")])])],1),_c('ul',{staticClass:"nav nav-pills wrap-1200",attrs:{"id":"pills-tab","role":"tablist"}},[_c('li',{staticClass:"nav-item col-6",attrs:{"role":"presentation"}},[_c('button',{staticClass:"w-100 nav-link",class:{ active: _vm.tab == 1 },attrs:{"id":"pills-1-tab","type":"button","role":"tab","aria-controls":"pills-1","aria-selected":"true"},on:{"click":function($event){return _vm.tab_change(1)}}},[_vm._v(" "+_vm._s(_vm.$t("button.tournament.tournament_condition"))+" ")])]),_c('li',{staticClass:"nav-item col-6",attrs:{"role":"presentation"}},[_c('button',{staticClass:"w-100 nav-link",class:{ active: _vm.tab === 2 },attrs:{"id":"pills-2-tab","type":"button","role":"tab","aria-controls":"pills-2","aria-selected":"false"},on:{"click":function($event){return _vm.tab_change(2)}}},[_vm._v(" "+_vm._s(_vm.$t("button.tournament.tournament_rank"))+" ")])])]),_c('div',{staticClass:"tab-content wrap-1200",attrs:{"id":"pills-tabContent"}},[_c('Router-View'),_c('div',{staticClass:"btn-bottom-wrapper"},[_c('b-button',{attrs:{"size":"xl","variant":"outline-primary","aria-label":_vm.$t('button.common.list')},on:{"click":function($event){_vm.$router
              .push({
                name: 'ShopTournamentSchedule',
                query: {
                  searchtype: _vm.$route.query.searchtype,
                  searchname: _vm.$route.query.searchname,
                },
              })
              .catch(() => {})}}},[_vm._v(" "+_vm._s(_vm.$t("button.common.list"))+" ")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }